footer {
    margin-top: 2rem;
    margin-right: 5%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 1rem;
}

@media only screen and (min-width: 1024px) {
    footer {
        margin-right: var(--wide-screen-margin);
    }
}

footer img {
    width: 1.5rem;
}

footer p {
    font-size: .8rem;
    font-weight: lighter;
    padding-top: .1rem;
}