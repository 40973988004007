.research-question {
    margin: -3rem auto 0 auto;
    padding-top: 8rem;
    display: flex;
    width: 80%;
    justify-content: space-between;
}

.research-question p {
    font-size: 1.2rem;
    font-weight: bold;
    padding: 3rem 1rem 3rem 1rem;
    margin: 0;
    align-self: center;
}

.quote-top-left {
    align-self: flex-start;
}

.quote-bottom-right {
    align-self: flex-end;
}

@media only screen and (min-width: 1024px) {
    .research-question {
        width: var(--wide-screen-column-width);
        margin: -3rem var(--wide-screen-margin) 0 auto;
    }
}