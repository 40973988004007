.participants-column {
    background-color: #EDEDED;
    padding: 2rem 5%;
    margin: 3rem 0 0 0;
    display: flex;
    flex-direction: column;
    gap: .5rem;
}

@media only screen and (min-width: 1024px) {
    .participants-column {
        padding: 2rem var(--wide-screen-margin) 2rem calc(var(--wide-screen-column-width) - var(--wide-screen-margin));
    }
}