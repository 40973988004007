.gallery-photo {
    position: relative;
}

.gallery-photo-text {
    display: flex;
    flex-direction: column;
    position: absolute;
    padding: .5rem 1.5rem .5rem .5rem;
    top: 0;
    left: 0;
    color: white;
    background-color: rgba(0, 0, 0, 0.459);
    transition: background-color .3s;
}

.gallery-photo-text:hover {
    background-color: rgba(0, 0, 0, 0);
}

.gallery-photo-text h3 {
    margin: 0 0 .3rem 0;
    font-size: .8rem;
    font-weight: lighter;
}

.gallery-photo-text h2 {
    margin: 0 0 1rem 0;
    font-size: 1.2rem;
}

.gallery-photo p {
    margin: 0;
    font-size: .8rem;
}