.project-intro {
    margin: auto;
    width: 90%;
    display: flex;
    flex-direction: column;
}

.duration {
    margin: 0 0 .5rem 0;
    font-size: 1rem;
    font-weight: normal;
    font-style: italic;
}

@media only screen and (min-width: 1024px) {
    .project-intro {
        margin: 0 var(--wide-screen-margin) 0 auto;
        width: var(--wide-screen-column-width);
    }
}

.project-intro h1 {
    font-size: 1.5rem;
    margin: 0;
}

.project-intro p {
    margin: 1rem 0 1rem 0;
}

.project-intro a {
    margin-top: 1rem;
}

.project-intro-image {
    margin-bottom: 2rem;
}