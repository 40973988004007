.project-links {
    display: none;
}

@media only screen and (min-width: 1024px) {
    .project-links {
        height: 0;
        display: flex;
        position: sticky;
        top: 30vh;
        margin-left: 10%;
        flex-direction: column;
        gap: 1rem;
        width: fit-content;
    }

    .project-links a {
        width: max-content;
        text-decoration: none;
        color: #838383;
        transition: transform .3s, color .3s;
    }

    .project-links a:hover {
        transform: translateY(-.2rem);
        color: black;
    }
}

@media only screen and (min-width: 1440px) {
}