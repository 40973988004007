.mini-card {
    display: flex;
    flex-direction: column;
    margin: auto;
    width: 100%;
}

.mini-card h1 {
    margin-top: 0;
    font-size: 1.2rem;
    margin-bottom: .6rem;
}

.mini-card p {
    margin: 0 0 1rem 0;
    font-size: .8rem;
}

.mini-card-media-container {
    display: flex;
    background-color: black;
    justify-content: center;
    margin-bottom: .5rem;
}

.mini-card img {
    margin-bottom: .5rem;
}

.mini-card a {
    margin-top: 1rem;
}