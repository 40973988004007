.about-intro {
    display: flex;
    width: 90%;
    margin: 0 auto 5rem auto;
    gap: .5rem;
    flex-direction: column;
    text-align: center;
    align-items: center;
}

.socials {
    display: flex;
    gap: 1rem;
    justify-content: center;
}

@media only screen and (min-width: 768px) {
    .about-intro {
        width: 70%;
        gap: 2rem;
        flex-direction: row;
        text-align: left;
    }

    .socials {
        justify-content: flex-start;
    }
}

.about-portrait {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.about-portrait img {
    width: 80%;
}

.socials img {
    width: 1.5rem;
}