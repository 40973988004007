.card-container {
    display: flex;
    background-color: white;
    flex-direction: column;
}

.card-container img {
    width: 100%;
    margin-bottom: .5rem;
}

.info-container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}


@media only screen and (min-width: 1024px) {
    .card-container {
        align-items: flex-start;
        flex-direction: row;
    }

    .card-container img {
        width: 30%;
        margin-bottom: 0;
    }

    .info-container {
        margin-left: 1rem;
        display: flex;
        align-self: stretch;
    }
}

.info-container h1 {
    margin-top: 0;
    font-size: 1.2rem;
    margin-bottom: .6rem;
}

.info-container p {
    margin: 0;
    font-size: .8rem;
}

.info-align-bottom {
    display: flex;
    flex-direction: column;
    margin-top: auto;
}

.info-align-bottom a {
    margin-top: 1rem;
}