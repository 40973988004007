.participant {
    background-color: white;
    display: grid;
    grid-template-columns: 1fr 4fr;
    column-gap: 1rem;
    padding: .5rem;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.participant-left-column {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: .5rem;
}

.participant-left-column img {
    width: 5rem;
}

.participant-left-column h3 {
    margin: 0;
}

.participant-right-column {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.participant-right-column p {
    margin: 0;
    font-size: .8rem;
}

.participant-quotes {
    display: flex;
    flex-direction: column;
    font-style: italic;
    gap: .3rem;
}