.project-column {
    display: flex;
    flex-direction: column;
    width: 90%;
    margin: -3rem auto 0 auto;
    padding-top: 8rem;
    gap: 2rem;
}

@media only screen and (min-width: 1024px) {
    .project-column {
        width: var(--wide-screen-column-width);
        margin: -3rem var(--wide-screen-margin) 0 auto;
    }
}

.project-column h3 {
    text-transform: uppercase;
    color: #7C7C7C;
    font-size: .8rem;
    font-weight: normal;
    margin: 0;
}

.project-column-description {
    margin: 0;
}

.project-image-caption {
    margin: -1.5rem 0 0 0;
    font-style: italic;
    font-size: .9rem;
    text-align: center;
}

.project-column ul {
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: .5rem;
}

.labeled-list {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.labeled-list p {
    margin: .5rem 0 0 0;
}

.labeled-list-heading {
    margin: 0;
    font-size: 1rem;
}