@import url('https://fonts.googleapis.com/css2?family=Itim&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

html {
  font-size: 12px;
  font-family: 'Roboto', Helvetica;
  scroll-behavior: smooth;
  --wide-screen-margin: 10%;
  --wide-screen-column-width: 50%;
}

body {
  margin: 0;
  padding: 0;
}

/* global classes - used in multiple components */
.column-with-image {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.column-with-image img {
  max-width: 100%;
}

/* added because safari bug makes image stretch */
img {
  width: 100%;
  height: auto;
}

.image-caption {
  margin-top: .5rem;
  font-style: italic;
  font-size: .9rem;
  text-align: center;
}

.image-caption-mini {
  margin-top: .5rem;
  font-style: italic;
  font-size: .7rem;
  text-align: center;
}

.button {
  text-decoration: none;
  background-color: #e91e63;
  width: fit-content;
  padding: .3rem 2rem;
  color: white;
}

.figma {
  width: 90%;
}

@media only screen and (min-width: 480px) {
  html {
    font-size: 14px;
  }
}

@media only screen and (min-width: 768px) {
  html {
    font-size: 16px;
  }
}

@media only screen and (min-width: 1024px) {
  html {
    font-size: 18px;
  }

  .column-with-image {
    flex-direction: row;
    justify-content: space-between;
  }
}

@media only screen and (min-width: 1440px) {
  html {
    font-size: 22px;
  }
}