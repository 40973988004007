.tags-container {
    display: grid;
    grid-template-columns: 1fr 4fr;
    align-items: baseline;
    margin-top: 1rem;
    grid-column-gap: .5rem;
}

.tag-name {
    text-transform: uppercase;
    margin: 0;
    font-size: .8rem;
}

.tags {
    display: flex;
    flex-wrap: wrap;
    gap: .3rem 1rem;
}


.tag {
    font-weight: normal;
    border-radius: .4rem;
    margin: 0;
}