.project-grid {
    background-color: #EDEDED;
    padding: 2rem 5%;
    margin: 3rem 0 0 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: .5rem .5rem;
}

@media only screen and (min-width: 1024px) {
    .project-grid {
        padding: 2rem var(--wide-screen-margin) 2rem calc(var(--wide-screen-column-width) - var(--wide-screen-margin));
    }
}

.project-grid div {
    background-color: white;
    padding: .5rem;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.project-grid img {
    width: 100%;
}