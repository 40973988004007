main {
    width: 90%;
    margin: auto;
}

@media only screen and (min-width: 1024px) {
    main {
        width: 70%;
    }

    .image-with-caption {
        width: 30%;
    }
}

.intro, .other-work-intro {
    font-size: 1.2rem;
}

.intro big {
    font-family: Itim;
    font-size: 2rem;
}

.cards-container {
    margin-top: 3rem;
    display: flex;
    flex-direction: column;
    gap: .5rem;
}

.cards-container hr {
    width: 100%;
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: none;
    height: .1rem;
    background-color: #CDCDCD;
}

.other-work-intro {
    margin-top: 3rem;
    text-align: center;
}