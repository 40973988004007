.nav-bar, .nav-bar-narrow {
    z-index: 1;
    position: fixed;
    width: 100%;
    box-sizing: border-box;
    padding: 0 5%;
    height: 4rem;
    background-color: rgba(255, 255, 255, 0.9);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.spacer {
    height: 4rem;
}

@media only screen and (min-width: 1024px) {
    .nav-bar {
        padding: .5rem var(--wide-screen-margin) 1rem;
    }

    .nav-bar-narrow {
        padding: .5rem 15% 1rem;
    }
}

.nav-links {
    display: flex;
    gap: 1.5rem;
}

.nav-links a {
    text-decoration: none;
    font-size: .8rem;
    font-weight: bold;
}

.nav-selected {
    color: #e91e63;
}

.nav-unselected, .nav-resume-link {
    color: black;
    transition: color .3s;
}

.nav-unselected:hover {
    color: #e91e63;
}

.nav-resume-link:hover {
    color: #e91e63;
    text-decoration: underline;
}

.logo {
    font-family: Itim;
    text-decoration: none;
    color: black;
    font-size: 1.5rem;
}